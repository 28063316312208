<template>
  <div class="relatorio-dre-init">
    <v-container fluid>
      <h1 class="titulo">Concursos Pautado em Concursos Recorrentes</h1>

      <v-form>
        <v-row justify="end">
          <v-col md="2">
            <v-menu :close-on-content-click="true" :nudge-right="35" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dateFormatted" label="Data"  prepend-icon="mdi-calendar" v-on="on" v-bind="attrs" readonly/>
              </template>
              <v-date-picker v-model="date" :allowed-dates="allowedDates" @input="formatarData()"/>
            </v-menu>
          </v-col>

          <v-col md="2">
            <v-select
              :items="sweepstakes_recurrences"
              :error-messages="selectedSweepstakeRecurrenceErrors"
              label="Concurso Recorrente"
              v-model="selectSweepstake"
              @blur="$v.selectSweepstake.$touch()">
            </v-select>
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <v-col class="text-right">
          <v-btn @click.prevent="submit" color="info" :loading="isLoading" @mouseover="visible = true">Criar concurso</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar.toggle" top right :color="snackbar.type">{{ snackbar.msg }}
      <v-btn dark text @click="snackbar.toggle = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import validator from './validator'

const namespaceStore = 'recorrentes'

export default {
  name: 'ConcursosRecorrentes',
  data: () => ({
    selectSweepstake: '',
    date: moment().format('YYYY-MM-DD'),
    dateFormatted: moment().format('DD/MM/YYYY'),
    isLoading: false,
    snackbar: {
      toggle: false,
      type: null,
      msg: null
    },
    allowedDates: val => moment(val).toDate() >= moment(moment().format('YYYY-MM-DD')).toDate()
  }),
  mounted () {
    let self = this
    self.buscarRecorrentes()
  },
  computed: {
    ...mapGetters(namespaceStore, ['sweepstakes_recurrences']),
    ...validator
  },
  methods: {
    ...mapActions(namespaceStore, ['getSweepstakesRecurrences', 'addSweepstakesRecurrences']),
    submit () {
      let self = this
      self.isLoading = true
      self.addSweepstakesRecurrences({selectedSweepstake: self.selectSweepstake, date: self.date}).then( async () => {
        self.isLoading = false
        self.snackbar.toggle = true
        self.snackbar.msg = 'Operação concluída'
        self.snackbar.type = 'success'
      }).catch((e) => {
        self.isLoading = false
        self.snackbar.toggle = true
        self.snackbar.msg = e.error
        self.snackbar.type = 'error'
      })
    },
    buscarRecorrentes () {
      let self = this
      self.getSweepstakesRecurrences().then( async () => {
        self.isLoading = false
      }).catch(() => {
        self.isLoading = false
      })
    },
    formatarData () {
      const self = this
      self. dateFormatted = moment(self.date).format('DD/MM/YYYY')
      console.log('sdgfjasdhf')
    }
  },
  validations () {
    return {
      selectSweepstake: { required }
    }
  }
}
</script>